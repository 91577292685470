<template>
  <div class="withdraw">
    <Navbar :title="$t('newAdd.Withdraw')">
      <template #right>
        <div class="right_btns" @click="rightClick">
          {{ $t("newAdd.Record") }}
        </div>
      </template>
    </Navbar>
    <div class="content">
      <div class="bank_item">
        <div class="label">
          <div class="label_title">{{ $t('addBank.name') }}</div>
          <div class="label_right">
            <div class="right_bank">{{ $t('Home.WithdrawSu.Balance') }}:</div>
            <div class="right_money">{{ userInfo.money || 0 }}</div>
          </div>
        </div>
        <div class="value">
          <van-field :value="bankList[bankRadio]?.name" readonly />
        </div>
      </div>
      <div class="bank_item">
        <div class="label">
          <div class="label_title">{{ $t('bank.amountAvailableWithdrawal') }}</div>
        </div>
        <div class="value">
          <van-field :value="userInfo.money" readonly />
        </div>
      </div>
      <div class="bank_item">
        <div class="label">
          <div class="label_title">{{ $t('withdraw.label.enterAmount') }}</div>
        </div>
        <div class="value">
          <van-field v-model="money" center v-if="country != 'tr'" clearable label-width="200" label="" type="number"
            :placeholder="$t('newAdd.EnterWithdrawalAmount')">
          </van-field>
        </div>
      </div>
      <div class="bank_item">
        <div class="label">
          <div class="label_title">{{ $t('bank.withdrawAccount') }}</div>
        </div>
        <div class="value">
          <div class="value_text">
            {{ bankList[bankRadio]?.bank }} {{ maskFirstFourChars(bankList[bankRadio]?.card) }}
          </div>
        </div>
      </div>
      <div class="bankInfo" v-if="bankList.length">
        <div class="bank_info">
          <div class="bank_info_item">
            <van-radio-group v-model="bankRadio" @change="radioChange">
              <van-radio :name="index" v-for="item, index in bankList" :key="index">
                {{ item.bank }} {{ maskFirstFourChars(item.card) }}
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="bank_please">
          <van-icon name="bag-o" color="#DDDDDD" size="28" />
          <span>{{ $t('bank.please_widthdraw') }}</span>
        </div>
      </div>
      <div class="bank_item">
        <div class="label">
          <div class="label_title">{{ $t('addBank.withdrawalPassword') }}</div>
        </div>
        <div class="value">
          <van-field v-model="paypass" :placeholder="$t('addBank.withdrawalPasswordPlaceholder')" />
        </div>
      </div>
      <div class="tips">
        <div class="tips_item" v-for="item, index in tips" :key="index">
          <div class="label">*</div>
          <div class="value">{{ $t(item) }}</div>
        </div>
      </div>
      <div class="btn">
        <button class="button" @click="Withdrawal">
          {{ $t("newAdd.AskNow") }}
        </button>
      </div>
    </div>
    <van-dialog v-model="isshowDialog" title="Password" show-cancel-button cancel-button-text="CANCEL"
      confirm-button-text="CONFIRM" confirmButtonColor="var(--theme)" @confirm="onSubmit" @cancel="editValue = ''">
      <van-field v-model="editValue" autofocus="true" type="password" :placeholder="$t('newAdd.EnterPwdPlaceholder')" />
    </van-dialog>
    <van-dialog v-model="showAddBankWarn" :title="$t('newAdd.warmTitle')" show-cancel-button
      :cancel-button-text="$t('btn.cancel')" :confirm-button-text="$t('btn.confirm')" confirmButtonColor="var(--theme)"
      @confirm="toAddBank" @cancel="$router.back()" class="warmDialog">
      <div class="dialog_content">
        {{ verConfig.needBank
          ? this.$t("newAdd.NotHaveCard")
          : this.$t("newAdd.NotHaveWallet")
        }}
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { GET_USER_BANK_INFO, WITHDRAW, getUserInfoNew } from "@/api";
import { isObject, count, getUserInfo, getToken } from "@/utils";
import { NavBar, cell, icon, field, Dialog, Toast, Tab, Tabs, RadioGroup, Radio } from "vant";
import Cookies from "js-cookie";
import Tabbar from "@/components/Tabbar";
import verConfig from "../../utils/verConfig";
import Navbar from "@/components/JNav";
import { maskMiddle } from "@/utils/common"
export default {
  name: "",
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    Tabbar,
    Navbar,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  data() {
    return {
      sms: "",
      country: Cookies.get("language"),
      money: "",
      bankInfo: {},
      userInfo: {},
      isshowDialog: false,
      editValue: "",
      money_not: 0,
      paypassword: this.$route.query.paypassword
        ? this.$route.query.paypassword
        : true,
      withdrawType: verConfig.needBank ? "bank" : "coin",
      rateData: {},
      showAddBankWarn: false,
      tips: ['bank.tips_1', 'bank.tips_2', 'bank.tips_3', 'bank.tips_4'],
      bankRadio: 0,
      bankList: [],
      paypass: ''
    };
  },
  created() {
    getUserInfoNew();
  },
  mounted() {
    this.getUserBankInfo();
    this.userInfo = getUserInfo();
  },
  methods: {
    radioChange(name) {
      this.bankRadio = name
    },
    maskFirstFourChars(input = '') {
      if (input == '') return;
      if (input.length <= 4) {
        return '****';
      }
      return '****' + input.slice(4);
    },
    rightClick() {
      this.$router.push({ name: "bankList", params: { type: 'withdrawRecord' } });
    },
    handleLinkPage(path) {
      if (getToken()) {
        this.$router.push(`/${path}`);
      } else {
        this.$toast($t("newAdd.NotLogin"));
        setTimeout(() => {
          this.$router.replace({ name: "login" });
        }, 1000);
      }
    },
    bankInfoInit(data) {
      this.bank = data.bank;
      this.bank = data.bank;
      this.bank_id = data.id;
      this.bank_name = data.name;
      this.bank_cardl4 = data.card_l4;
      this.bankInfo = { ...data, card: maskMiddle(data.card) };
      if (!!data.open && data.open !== "bank") {
        this.withdrawType = "coin";
      } else {
        this.withdrawType = "bank";
      }
    },
    toAddBank() {
      this.itemClick("BankCardAdd");
    },
    getUserBankInfo() {
      const bankInfo = JSON.parse(this.$cookie.get("selectBankInfo"));
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_USER_BANK_INFO().then((r) => {
        Toast.clear();
        if (r.data.ret === 1) {
          Toast.clear();
          if (isObject(r.data.data) && count(r.data.data) > 0) {
            const banklist = r.data.data
            this.bankList = r.data.data
            if (bankInfo) {
              if (banklist.some(item => item.id === bankInfo.id)) {
                this.bankInfoInit(bankInfo)
                return
              }
              this.bankInfoInit(banklist[0]);
              this.$cookie.set("selectBankInfo", JSON.stringify(banklist[0]))
            } else {
              this.bankInfoInit(banklist[0]);
              this.$cookie.set("selectBankInfo", JSON.stringify(banklist[0]))
            }
          } else {
            Toast.clear();
            this.showAddBankWarn = true;
          }
        } else {
          Toast.clear();
          Toast(this.$t("newAdd.GetBankInfoFail"));
        }
      }).catch((e) => {
        Toast.clear();
        console.error(e);
      });
    },
    itemClick(page) {
      this.$router.push({ name: page });
    },
    allHandler() {
      this.money = this.userInfo.money;
    },
    Withdrawal() {
      this.confirm();
    },
    confirm() {
      if (!this.money) return this.$toast(this.$t('newAdd.EnterWithdrawalAmount'));
      if (!this.paypass) return this.$toast(this.$t('addBank.withdrawalPasswordPlaceholder'));
      Toast.loading({ forbidClick: true, duration: 0 });
      WITHDRAW({
        money: this.money,
        bankid: this.bankList[this.bankRadio].id,
        paypass: this.paypass
      }).then((r) => {
        Toast.clear();
        if (r.data.ret === 1) {
          Toast.clear();
          Toast(r.data.msg);
          getUserInfoNew();
          this.$router.back();
        } else {
          Toast.clear();
          Toast(r.data.msg);
        }
      }).catch((e) => {
        Toast.clear();
      });
    },
    async onSubmit() {
      this.isshowDialog = false;
      if (this.editValue.trim().length !== 4) {
        Toast(this.$t("newAdd.EnterValidPayPwd"));
      } else {
        const { data } = await extract({
          money: this.money,
          paypass: this.editValue,
          bankid: this.bankInfo.id,
        });
        Toast(data.msg);
        this.editValue = "";
        this.$router.push("/login");
      }
    },
  },
  computed: {
    money_can() {
      return this.userInfo.money_not > 0 ? 0 : this.userInfo.money;
    },
    actualAmount() {
      let amount = 0;
      if (Number(this.money)) {
        if (String(this.rateData.value).indexOf("%") === -1) {
          amount = (this.money - this.rateData.value) / this.rateData.rate;
        } else {
          const fixedNum = 100000000;
          amount =
            Math.round(
              ((this.money *
                (1 -
                  String(this.rateData.value).substring(
                    0,
                    String(this.rateData.value).indexOf("%")
                  ) /
                  100)) /
                this.rateData.rate) *
              fixedNum
            ) / fixedNum;
        }
      }
      return amount > 0 ? amount : 0;
    },
  },
};
</script>

<style lang="less" scoped>
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
@comBG: var(--light);

.withdraw {
  min-height: 100vh;
  background: var(--bg);

  .warmDialog {
    background: var(--bg);

    .dialog_content {
      font-size: calc(15rem / 16);
      padding: calc(18rem / 16);
      color: var(--textColor);
    }

    /deep/ .van-dialog__cancel,
    /deep/ .van-dialog__confirm {
      background: var(--bg) !important;
    }

    /deep/ .van-dialog__cancel {
      color: #4acfff;
    }

    /deep/ .van-dialog__header {
      color: var(--theme);
    }
  }

  .withdraw_amount {
    margin: @wrapPadding;

    .title {
      color: var(--textColor);
      font-size: calc(14rem / 16);
      text-indent: 0.6em;
    }

    .van-cell {
      margin: calc(6rem / 16) 0 0;
    }
  }

  .content {
    padding: calc(10rem / 16) calc(16rem / 16) 0;

    .bank_item {
      margin-bottom: calc(15rem / 16);

      .label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: calc(15rem / 16);

        &_title {
          color: var(--textColor);
          font-size: calc(15rem / 16);
        }

        &_right {
          display: flex;
          align-items: center;

          .right_bank {
            color: #959596;
            font-size: calc(12rem / 16);
            margin-right: calc(10rem / 16);
          }

          .right_money {
            color: #d4a85e;
            font-size: calc(20rem / 16);
            font-weight: 700;
          }
        }
      }

      .value {
        /deep/ .van-cell {
          background-color: var(--light);
          border: 1px solid transparent;
          height: calc(50rem/ 16);
          padding: calc(10rem / 16);
          box-sizing: border-box;
          border-radius: calc(10rem / 16);

          .van-field__body {
            height: 100%;

            input {
              color: var(--textColor);
              font-size: calc(15rem / 16);
            }
          }
        }

        &_text {
          background-color: var(--light);
          border: 1px solid transparent;
          height: calc(50rem/ 16);
          padding: calc(10rem / 16);
          box-sizing: border-box;
          border-radius: calc(10rem / 16);
          display: flex;
          align-items: center;
          color: var(--textColor);
        }
      }
    }

    .bankInfo {
      background-color:var(--light);
      border: 1px solid transparent;
      padding: calc(10rem / 16);
      box-sizing: border-box;
      border-radius: calc(10rem / 16);
      margin-bottom: calc(15rem / 16);

      .bank_info {
        &_item {
          /deep/ .van-radio {
            padding-bottom: calc(8rem / 16);
          }

          /deep/ .van-radio__icon .van-icon {
            background-color: var(--light);
          }

          /deep/.van-radio__icon--checked .van-icon {
            background-color: #1989fa;
          }

          /deep/ .van-radio__label {
            color: var(--textColor);
            font-size: calc(15rem / 16);
          }
        }
      }

      .bank_please {
        display: flex;
        align-items: center;
        margin-top: calc(5rem / 16);

        span {
          color: var(--textColor);
          font-size: calc(14rem / 16);
        }
      }
    }

    .tips {
      &_item {
        display: flex;

        .label {
          color: var(--red);
          margin-right: calc(10rem / 16);
        }

        .value {
          color: var(--red);
          font-size: calc(14rem / 16)
        }
      }
    }

    .btn {
      padding: calc(20rem / 16) 0;

      .button {
        width: 100%;
        height: calc(43rem / 16);
        font-size: calc(15rem / 16);
        background: var(--red);
        border-radius: calc(5rem / 16);
        border: none;
        color: var(--light);
      }
    }
  }

  .right_btns {
    background: var(--red);
    border-radius: calc(5rem / 16);
    border: none;
    color: var(--light);
    padding: calc(5rem / 16) calc(15rem / 16);
  }
}
</style>